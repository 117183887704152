.services {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-bottom: 20%;
  
}

.services h1 {
  font-family:"Noto Sans", sans-serif;
  padding-top: 10%;
  margin-bottom: -50px;
}


.calendly-button{
  align-items: center;
  background: none;
  border: none;
  font-family:"Noto Sans", sans-serif;
  cursor:pointer;
}



.service-cat-container {
  align-items: center;
  display: flex;
  flex-wrap: wrap;
  justify-content:space-around;
  width: 100%;
  align-items: center;
}

.cat {
  flex: 0 50%;
  height: 200px;
  width: 100px;
  margin-top: 10%;
}

.cat h3 {
  font-size: 40px;
  letter-spacing: .3rem;
  margin-bottom: 5%;
}

.cat-text {
  font-family:"Noto Sans", sans-serif;
  font-weight: 300;
  height: 200px;
  width: 75%;
  margin: auto;
}

.cat-text p {
  font-size: 18px;
}

.cat-text li {
  list-style-type: none;
  /* list-style-type:square; */
}


@media only screen and (max-width: 600px) {


  .calendly-button h1{
   font-size: 18px;
   margin-top: 40px;
   margin-bottom: 25px;
  }
  
.service-cat-container{ 
  display: flex;
  flex-direction: column;
  }
  .cat {
    height: 200px;
    width: 100vw;
    margin-top: 10%;
    margin-bottom: 100px;
  }  
  .cat h3 {
    font-size: 30px;
    letter-spacing: .3rem;
  }
  .cat-text {
    height: 200px;
    width: 100%;
  }
  .cat-text li {
    
    list-style-type: none;
  }
}