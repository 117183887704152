.rservices {
  display: flex;
  flex-direction: column;
  align-items: center;
  /* padding-top: 5%; */
  padding-bottom: 15%;
}

.rheader h1 {
  font-size: 55px;
  font-weight: 400;
  letter-spacing: .3rem;
  margin-bottom: -20px;
}

.rservice-cat-container {
  align-items: center;
  display: flex;
  flex-wrap: wrap;
  justify-content:space-around;
  width: 100%;
  align-items: center;
}

.rcat {
  flex: 0 50%;
  height: 200px;
  width: 100px;
  margin-top: 10%;
}

.rcat h3 {
  font-size: 40px;
  font-weight: 350;
  letter-spacing: .3rem;
  margin-bottom: 5%;
}

.rcat-text {
  font-family:"Noto Sans", sans-serif;
  font-weight: 300;
  height: 200px;
  width: 75%;
  margin: auto;
}

.rcat-text p {
  font-size: 18px;
}

.rcat-text li {
  list-style-type: none;
}


@media only screen and (max-width: 600px) {

  .rservices {
    padding-bottom: 0;
  }
  
  .rheader h1 {
    font-size: 45px;
    margin-bottom: 10px;
  }

  .rservice-cat-container{ 
    display: flex;
    flex-direction: column;
    }
    .rcat {
      height: 200px;
      width: 90vw;
      margin-top: 10%;
      margin-bottom: 100px;
    }  
    .rcat h3 {
      font-size: 30px;
      letter-spacing: .3rem;
    }
    .rcat-text {
      height: 200px;
      width: 100%;
    }
    .rcat-text li {
      
      list-style-type: none;
    }
  }