.footer {
  display: flex;
  width: 100%;
  margin-top: 100px;
  background-color: black;
}

.firstcontactbox-container{
display: flex;
flex-direction: column;
align-items: center;
margin: 0 auto;
}

.contact-header{
  display: flex;
  color: white;
  font-size: 20px;
  letter-spacing: .2rem;
  margin-top: 30px;
}

.location-icon{
  filter: invert(100%) sepia(100%) saturate(13%) hue-rotate(237deg) brightness(104%) contrast(104%);  
  margin-right: 30px;
}
.email-icon{
  filter: invert(100%) sepia(100%) saturate(13%) hue-rotate(237deg) brightness(104%) contrast(104%);  
  margin-right: 30px;
}

.phone-icon{
  filter: invert(100%) sepia(100%) saturate(13%) hue-rotate(237deg) brightness(104%) contrast(104%);  
  margin-right: 30px;
}

a {
  color: white;
  text-decoration: none; 
}

.secondcontact-container{
  display: flex;
  margin: 0 auto;
  width: 800px;
  justify-content: space-around;
  align-items: center;
}

.contactinfo-container{
  display: flex;
  flex-direction: column;
  height: 100px;
  justify-content: space-between;
  color: white;
  font-size: 20px;
  margin-top: -35px;
}

.contactform-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 10%;
  margin-bottom: 15%;
}

.form-item {
  position: relative;
  margin-top: 15px;
}

.title-box h1{
color: white;
font-family:"Noto Sans", sans-serif;
font-size: 20px;
font-weight: 300;
}

label, input, textarea {
  font-family:"Noto Sans", sans-serif;
}

label {
  position: absolute;
  top: 10px;
  left: 2%;
  color: #999;
  font-size: clamp(14px, 1.5vw, 16px);
  pointer-events: none;
  user-select: none;
}

input, textarea {
  width: 100%;
  outline: 0;
  border: 1px solid #ccc;
  margin-bottom: 20px;
  padding: 12px;
  font-size: clamp(15px, 1.5vw, 18px);
}

input:focus+label, 
input:valid+label, 
textarea:focus+label, 
textarea:valid+label {
  font-size: clamp(13px, 1.3vw, 16px);
  color: #777;
  top: -20px;
  transition: all .225s ease;
}

.submit-btn {
  background-color: #000000;
  filter: drop-shadow(2px 2px 3px #0003);
  color: #fff;
  font-size: clamp(16px, 1.6vw, 16px);
  font-family:"Noto Sans", sans-serif;
  letter-spacing: .1rem;
  display: block;
  margin: 0 auto;
  border: none;
  user-select: none;
  transition: 0.2s;
}

.submit-btn:hover {
  transform: scale(1.1, 1.1);
}

.submit-btn:active {
  transform: scale(1.1, 1.1);
  filter: sepia(0.5);
}

@media only screen and (max-width: 600px) {
  .footer {
    margin-top: 175px;
    height: 60vh;
    width: 100vw;
  }
  .contact-header{
    font-size: 16px;
  }
  .secondcontact-container{
    width: 100%;
  }
    
.contactinfo-container{
  font-size: 18px;
  color: white;
  height: 100px;
  width: 90%;
  margin-top: 40px;
}
    .location-icon{
    margin-right: 20px;
    }
    .email-icon{
      margin-right: 20px;
    }
    
    .phone-icon{
      margin-right: 20px;
    }
  .contactform-container {
    display: none;
  }
  
}  

