.about-container{
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 255px;
}

.about-title{
  font-size: 35px;
  letter-spacing: .3rem;
}


@media only screen and (max-width: 640px){
  .about-container{
    height: 250px;
    display: flex;
    justify-content: center;

  
  }
  .about-title{
    font-size: 20px;
  }
}