.App {
  display: flex;
  flex-direction: column;
  align-content: center;
  text-align: center;
  overflow-x: clip;
}

html, body {
  padding: 0;
  margin: 0;
  }

 @media only screen and (max-width: 600px) {
  .App{
    width: 100vw;
    display:flex;
    flex-direction: column;
    text-align: center;
    overflow:clip;
  }
}  