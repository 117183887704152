.filler-banner {
 display: flex;
 flex-direction: column;
 justify-content: space-around;
 align-items: center;
  height: 50vh;
  width: 100%;
  background-image:url(arindam-mahanta-VEOk8qUl9DU-unsplash.jpg) ;
  background-position: bottom;
  background-color: rgba(0, 0, 0, 0.5);
  background-blend-mode: multiply;
}
.fillerbanner-text{
  font-family:"Noto Sans", sans-serif;
  font-weight: 500;
  width: 75%;
  font-size: 22px;
  color: white;
}

@media only screen and (max-width: 640px){
  .filler-banner{
    background-position: bottom;
    clear: both;
  }
  .fillerbanner-text{
    font-family:"Noto Sans", sans-serif;
    font-weight: 500;
    width: 85%;
    font-size: 18px;
  }
}
