.calendly-component {
  display: flex;
  flex-direction: column;
  align-items: center;
  /* padding-top: 5%; */
  /* padding-bottom: 20%; */
  /* margin-bottom: -5%; */
}

.calendly-component h1 {
  font-family:"Noto Sans", sans-serif;
}


.calendly-button{
  align-items: center;
  background: none;
  border: none;
  font-family:"Noto Sans", sans-serif;
  cursor:pointer;
}
@media only screen and (max-width: 600px) {

  .calendly-component {
    margin-top: -15%;
    margin-bottom: -20%;
  }
  

  .calendly-component h1 {
    font-size: 20px;
  }

  }