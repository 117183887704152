.cservices {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-bottom: 15%;
  background-image:url(server-room.jpg);
  background-position: bottom;
  background-size: contain;
  background-color: rgba(0, 0, 0, 0.655);
  background-blend-mode: multiply;
}

.cheader h1 {
  font-size: 55px;
  font-weight: 400;
  letter-spacing: .3rem;
  padding-top: 20px;
  margin-bottom: -20px;
  color: white;
}

.cservice-cat-container {
  display: flex;
  flex-wrap: wrap;
  justify-content:center;
  width: 100%;
  margin-bottom: -75px;
}

.ccat {
  flex: 0 50%;
  height: 300px;
  width: 300px;
  margin-top: 5%;
}

.ccat-text {
  font-family:"Noto Sans", sans-serif;
  font-weight: 300;
  height: 200px;
  width: 75%;
  margin: auto;
  color: white;
}

.ccat-text p {
  font-size: 18px;
}

.ccat-text li {
  list-style-type: none;
}

@media only screen and (max-width: 600px) {

  .cservices {
    padding-bottom: 150px;
  background-size:cover;
  margin-bottom: 150px;
  }
  
  .cheader h1 {
    font-size: 45px;
  }

  .cservice-cat-container{ 
    display: flex;
    flex-direction: column;
    }
    .ccat {
      height: 200px;
      width: 100vw;
      margin-top: 10%;
      margin-bottom: 100px;
    }  
    .ccat h3 {
      font-size: 30px;
      letter-spacing: .3rem;
    }
    .ccat-text {
      height: 200px;
      width: 100%;
    }
    .ccat-text li {
      
      list-style-type: none;
    }
  }