.dif-body{
  display: flex;
  flex-direction: column;
  width: 1000px;
  margin: 0 auto;
}

.diftitle-header{
  font-family:"Noto Sans", sans-serif;
  font-size: 50px;
  font-weight: 300;
  margin-bottom: 60px;
  margin-top: 40px;
}

.dif-textbox{
  font-family:"Noto Sans", sans-serif;
  font-size: 18px;
  font-weight: 300;
  padding-bottom: 50px;
  letter-spacing: .1rem;
}

@media only screen and (max-width: 600px) {
  .dif-body{
    width: 100%;
  }
  .diftitle-header{
    font-size: 40px;
  }
  .dif-textbox{
    font-size: 16px;
    font-weight: 300;
   
  }
  }