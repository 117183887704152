nav {
  font-family:"Noto Sans", sans-serif;
  text-align:center;
  width: 100%;
  height: 60px;
  position:relative;
  background-color:rgba(0, 0, 0, 0.1);
  margin-bottom: 100px;
}

nav ul {
  list-style:none;
  padding:0 20px;
  margin: 0;
  height: 60px;
  
}

nav ul li {
  display: inline-block;
}

nav ul li a {
  color:black;
  font-family:"Noto Sans", sans-serif;
  font-size: 15px;
  display:block;
  padding:0px 40px;
  text-decoration:none;
  float: left;
  height: 60px;
   line-height: 60px;
}

nav ul li:hover {
  background-color: rgba(0, 0, 0, 0.1);

}


nav ul li:hover > a{
    color: white;
}

nav ul li:hover > ul {
  display:block;
}

nav ul ul {
  background-color: rgba(0, 0, 0, 0.1);
  padding:0;
  text-align: center;
  display:none;
  width: 100%;
  position: absolute;
  top: 60px;
  left: 0px;
}

.hamburger{
  display: none;
}

.bar {
  display: block;
  width: 25px;
  height: 3px;
  margin: 5px auto;
  -webkit-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
  background-color: #101010;
}

.mobile-nav{
display: none;
}


        @media only screen and (max-width: 640px){
          .desk-nav {
            display: none; 
        }
        .mobile-nav{
          display: block;
          }
      }