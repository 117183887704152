.header {
  background-color: black;
  background-size: 100%;
  background-repeat: no-repeat;
  margin: auto;
  padding-top: 100px;
  /* z-index: 1; */
  position: sticky;
}

.logo{
  height: 350px;
  margin-bottom: 50px;
  filter: invert(100%) sepia(100%) saturate(13%) hue-rotate(237deg) brightness(104%) contrast(104%);  
}
/* Small devices such as large phones (640px and up) */
@media only screen and (max-width: 640px) {
  .header {
    background-color: black;
    background-size: 100%;
    padding-top: 0px;
    position: sticky;
    padding-top: 50px;
    padding-bottom: 50px;
  }
  
  .logo{
    height: 200px;
    margin-bottom: 0px;
    filter: invert(100%) sepia(100%) saturate(13%) hue-rotate(237deg) brightness(104%) contrast(104%);  
  }
}

/* Medium devices such as tablets (768px and up) */
/* @media only screen and (min-width: 768px) {

} */