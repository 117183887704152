.DropdownNav ul li {
  list-style: none;
  /* margin-top:10px; */
}

.menu-trigger{
  position: absolute;
  right: 20px;
  height: 60px;
  width: 60px;
  border-radius: 50%;
  overflow: hidden;
  cursor: pointer;
  background-color: #fff;
}

.dropdown-menu{
  position: absolute;
  top: 75px;
  right: 20px;
  background-color: #f4f4f4;
  padding: 10px 20px;
  width: 250px;
  height: 550px;
}

.dropdown-menu::before{
  content: '';
  position: absolute;
  top: -5px;
  right: 20px;
  height: 20px;
  width: 20px;
  background: var(--secondary-bg);
  transform: rotate(45deg);
}

.dropdown-menu.active{
  opacity: 1;
  visibility: visible;
  transform: translateY(0);
  transition: var(--speed) ease;
}

.dropdown-menu.inactive{
  opacity: 0;
  visibility: hidden;
  transform: translateY(-20px);
  transition: var(--speed) ease;
}


.dropdown-menu ul li{
  border-bottom: 1px solid rgba(0, 0, 0, 0.05);
}

.dropdown-link{
  /* display: flex; */
  margin: 5px auto;
}

.dropdown-link{
  margin-right: 10px;
  /* opacity: 0.5; */
}

.dropdown-link{
  max-width: 100px;
  margin-left: 10px;
}